$themeColor: #fff;
$colors: (
		white: #ffffff,
		black: #3f3a35,
);

@import "~swiper/swiper";
@import "~swiper/components/navigation/navigation";
@import "~swiper/components/pagination/pagination";

//@import "~swiper/components/a11y/a11y";
//@import "~swiper/components/controller/controller";
//@import "~swiper/components/effect-coverflow/effect-coverflow";
//@import "~swiper/components/effect-cube/effect-cube";
//@import "~swiper/components/effect-fade/effect-fade";
//@import "~swiper/components/effect-flip/effect-flip";
//@import "~swiper/components/lazy/lazy";
//@import "~swiper/components/scrollbar/scrollbar";
//@import "~swiper/components/thumbs/thumbs";
//@import "~swiper/components/zoom/zoom";

/*** Custom ***/

/* Pagination */
.swiper-pagination {
	position: unset;
	padding: 2rem 0 1px 0;
}

.swiper-pagination-bullet {
	width: 10px;
	height: 10px;
	border: #3f3a35 1px solid;
	background-color: white;
	opacity: 0.5;
}

.swiper-pagination-bullet-active {
	opacity: 1;
	background: #3f3a35;
	transform: scale(1.2);
}

/* Navigation */
.swiper-button-prev,
.swiper-button-next {
	display: none;
}

.swiper-container {
	width: 100%;
	height: 100%;

	&:hover {
		.swiper-button-prev,
		.swiper-button-next {
			display: block;
		}
	}
}

.swiper-text {
	display: block;
	text-align: center;
	font-size: 16px;
	margin-top: 10px;
}
